import React from "react";
import Layout from "../components/layout/layout";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import * as styles from "../styles/bookACall.module.scss"
import { Helmet } from "react-helmet";
import { InlineWidget } from "react-calendly";

export default function BookACallPage() {
    return <>
        <Helmet>
            <title>Book a call - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header transparent={true}/>}
            footer={<Footer/>}
        >
            <div className={styles.pageTitle}>Book a call</div>
            <div className={styles.pageHeader}>Let’s chat</div>
            <div style={{ height: '850px' }}>
                <InlineWidget
                    styles={{ height: '750px' }}
                    url="https://calendly.com/brettleverton"
                    pageSettings={{
                        hideLandingPageDetails: true,
                        hideEventTypeDetails: true
                    }}
                />
            </div>
        </Layout>
    </>
}
